import { Component, OnInit } from '@angular/core';
import { Product, Brand, Category, PRICES } from '@models/models.model';
import { CategoryService, CollectionService, ProductService } from '@services/services.service';
import { BasicComponent } from '@pages/basic/basic.component';
import { ActivatedRoute } from '@angular/router';
import { BrandService } from '@app/services/brand.service';
import {Location} from '@angular/common';

@Component({
	selector: 'app-products',
	templateUrl: './products.component.html',
	styleUrls: ['./products.component.scss']
})
export class ProductsComponent extends BasicComponent implements OnInit {
	page: number = 0;
	limit: number = 100;
	categoryId: any = null;
	collectionId: any = null;
	categories: Category[] = [];
	subcategories: any[] = [];
	brands: Brand[] = [];
	prices: any[] = PRICES;
	products: Product[] = [];
	selectedSubcategories: number[] = [];
	selectedBrands: number[] = [];
	selectedPrices: string[] = [];
	image: string;
	title: string = 'Produtos';
	col: number = 3;
	isCollapsedCategories: boolean = false;
	isCollapsedSubcategories: boolean = false;
	isCollapsedBrands: boolean = false;
	isCollapsedPrices: boolean = false;
	verifyProducts: boolean = false;
	loading = false;
  mainLoading = true;
  showFilters: boolean = false;
  hasImage: boolean = false;


	constructor(
    private productService: ProductService,
    private activatedRoute: ActivatedRoute,
    private brandService: BrandService,
    private categoryService: CategoryService,
    private collectionService: CollectionService,
    private location: Location) {
		super();

    if (window.screen.width > 991) {
      this.showFilters = true;
    }
	}

	ngOnInit(): void {
		this.activatedRoute.params.subscribe(async (params: any) => {
			this.categoryId = null;
			this.selectedSubcategories = [];
			this.selectedBrands = [];
			this.selectedPrices = [];

			if (params.category) {
				this.categoryId = await Number(params.category);
        this.hasImage = true;
      }

      if (params.subcategory) {
        this.selectedSubcategories.push(Number(params.subcategory));
        this.image = 'assets/images/' + params.slug + '.png';
        this.hasImage = true;
      }

      if (params.brand) {
        this.selectedBrands.push(Number(params.brand));
        this.hasImage = true;
      }

			if (params.collection) {
				this.collectionId = await (params.collection);
				this.loadCollection();
        this.hasImage = true;
      }

      this.activatedRoute.queryParams.subscribe(async (params: any) => {
        if(params.s) {
          this.selectedSubcategories = [];
          let s = params.s.split(',');
          s.forEach((e: any) => {
            this.selectedSubcategories.push(Number(e));
          });
        }

        if(params.b) {
          this.selectedBrands = [];
          let b = params.b.split(',');
          b.forEach((e: any) => {
            this.selectedBrands.push(Number(e));
          });
        }
      });

      this.loadCategories();
      this.loadSubcategories();
      this.loadBrands();
      await this.loadProducts();

    if(!this.hasImage) {
      this.image = 'assets/images/products.png';
    }
		});
	}

	/** Loads **/
	loadCategories() {
		this.categoryService.all().subscribe(async (resp: any) => {
			if (resp.status == 'OK') {
				this.categories = resp.data;

				this.categories.map((category: any) => {
					category.checked = false;
					if (category.id == this.categoryId) {
						category.checked = true;
            if(!this.image) {
              this.image = category.image;
              this.mainLoading = false;
            }
					}
				});
			}
		});
	}

	loadSubcategories() {
		this.categoryService.subcategories(this.categoryId).subscribe(async (resp: any) => {
			if (resp.status == 'OK') {
				this.subcategories = await resp.data;

				this.subcategories.map((subcategory: any) => {
					subcategory.checked = false;
					if (this.selectedSubcategories.includes(subcategory.id)) {
						subcategory.checked = true;
					}
				});
			}
		});
	}

	loadBrands() {
		this.brandService.all(this.categoryId).subscribe(async (resp: any) => {
			if (resp.status == 'OK') {
				this.brands = await resp.data;

				this.brands.map((brand: any) => {
					brand.checked = false;
					if (this.selectedBrands.includes(brand.id)) {
						brand.checked = true;
						this.image = brand.banner;
            this.mainLoading = false;
					}
				});
			}
		});
	}

	loadCollection() {
		this.collectionService.one(this.collectionId).subscribe(async (resp: any) => {
			if (resp.status == 'OK') {
				this.image = resp.data.banner;
        this.mainLoading = false;
			}
		});
	}

	async loadProducts(clear: boolean = true) {
		this.loading = true;

		if (this.page == 0) {
			this.products = [];
		}

		if (clear) {
			this.products = [];
			this.verifyProducts = false;
		}

		this.productService.filterCategory(this.page, this.limit, this.categoryId, this.selectedSubcategories, this.selectedBrands, this.selectedPrices, this.collectionId).subscribe(async (resp: any) => {
			if (resp.status == 'OK') {
				if (this.page == 0) {
					this.products = await resp.data;
				} else {
					this.products = this.products.concat(resp.data);
				}

				if (resp.data.length == 0 || resp.data.length < this.limit) {
					this.page = -1;
				}

				this.products.map((product: any) => {
					product.image2 = product.images ? product.images[1] : '';
				});

				if (!this.products.length) {
					this.verifyProducts = true;
				}
			} else {
				this.page = -1;
			}

			this.loading = false;
      if(this.image) {
        this.mainLoading = false;
      }
		});
	}

	seeMore() {
		if (this.page >= 0) {
			this.page++;

			this.loadProducts(false);
		}
	}

	/** Changes **/

	gridChange(columns: number) {
		this.col = columns;
	}

	async categoryChange(event: any) {
		this.categoryId = event.target.checked ? Number(event.target.value) : null;
		this.selectedSubcategories = [];
		this.selectedBrands = [];
		this.page = 0;

		this.categories.map((category: any) => {
			category.checked = false;
			if (category.id == this.categoryId) {
				category.checked = true;
				this.image = category.image;
				this.title = category.name;
			}
		});

    this.loadSubcategories();
    this.loadBrands();
		await this.loadProducts();
	}

	async subcategoryChange(event?: any) {
		this.page = 0;
		this.selectedBrands = [];

		if (event.target.checked) {
			this.selectedSubcategories.push(Number(event.target.value));
		} else {
			const index = this.selectedSubcategories.indexOf(Number(event.target.value), 0);
			if (index > -1) {
				this.selectedSubcategories.splice(index, 1);
			}
		}

		this.subcategories.map((subcategory: any) => {
			subcategory.checked = this.selectedSubcategories.includes(subcategory.id);
		});

    let url = this.getBaseUrl();
    if(this.selectedSubcategories.length) {
      url += '?s=' + this.selectedSubcategories.join(',');
    }

    this.updateUrl(url);
		await this.loadProducts();
	}

	async brandChange(event?: any) {
		this.page = 0;

		if (event.target.checked) {
			this.selectedBrands.push(Number(event.target.value));
		} else {
			const index = this.selectedBrands.indexOf(Number(event.target.value), 0);
			if (index > -1) {
				this.selectedBrands.splice(index, 1);
			}
		}

		this.brands.map((brand: any) => {
			brand.checked = this.selectedBrands.includes(brand.id);
		});

    let url = this.getBaseUrl();
    if(this.selectedBrands.length) {
      if(this.selectedSubcategories.length) {
        url += '?s=' + this.selectedSubcategories.join(',');
        url += '&b=' + this.selectedBrands.join(',');
      }
    } else {
      if(this.selectedSubcategories.length) {
        url += '?s=' + this.selectedSubcategories.join(',');
      }
    }

    this.updateUrl(url);
		await this.loadProducts();
	}

	async priceChange(event?: any) {
		if (event.target.checked) {
			this.selectedPrices.push(event.target.value);
		} else {
			const index = this.selectedPrices.indexOf(event.target.value, 0);
			if (index > -1) {
				this.selectedPrices.splice(index, 1);
			}
		}

		this.prices.map((price: any) => {

			price.checked = this.selectedPrices.includes(price.id);
		});

		await this.loadProducts();
	}

  getBaseUrl() {
    return window.location.href.split('/').slice(3).join('/').split('?')[0];
  }

  updateUrl(url: string) {
    this.location.replaceState(url)
  }
}
