import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {OrderService, PaymentService} from '@services/services.service';
import { BasicComponent } from '@pages/basic/basic.component';
import {Merchant, Order, User} from '@models/models.model';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-order-pay',
	templateUrl: './order-pay.component.html',
	styleUrls: ['./order-pay.component.scss']
})
export class OrderPayComponent extends BasicComponent implements OnInit {
  @ViewChild('paymentForm') paymentForm: ElementRef;
  @ViewChild('sibsModal', { static: true }) sibsModal: ElementRef;

	user: User;
  order: Order;
  merchant: Merchant;
	orderId: number;
  checkoutId: number;
  spgContext: string;
  spgConfig: string;
  spgStyle: string;
  loading: boolean = false;
  isSpg: boolean = false;
  display = 'none';

	constructor(private orderService: OrderService, private location: Location, private activatedRoute: ActivatedRoute, private paymentService: PaymentService, private renderer2: Renderer2) {
		super();

		this.user = this.storageService.get('user');
    this.merchant = this.storageService.get('merchant');

    if (!this.merchant || !this.user) {
      this.navigateHome();
    }
    this.isSpg = this.merchant.payments.cc == 'sibs-spg';

		if (!this.user) {
			this.navigateHome();
		}

		this.activatedRoute.params.subscribe(async (params: any) => {
			this.orderId = params.id;
			this.load();
		});
	}

	ngOnInit(): void {}

	load() {
		this.orderService.one(this.orderId).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.order = resp.data;

        if (this.order.status == 'Submetida' && this.order.payment_type == 'MASTER VISA') {
          this.checkoutId = this.order.checkout_id;

          if (this.isSpg) {
            this.spgContext = this.order.sibsspg_form_context;
            this.spgConfig = '{"paymentMethodList": [], "amount": { "value": ' + this.order.total + ', "currency": "EUR" },"language": "pt","redirectUrl": "' + this.getBaseUrl() + '/encomenda/' + this.orderId + '", "customerData": null}';
            this.spgStyle = '{"layout": "default","theme": "default", "color": {"primary": "' + this.merchant.color + '", "secondary": "' + this.merchant.color2 + '","border": "","surface": "","header": {"text": "","background": "#FFFFFF"},"body": {"text": "","background": "#FFFFFF"}},"font":""}';

            const sibsSpgPaymentWidget = this.renderer2.createElement('script');
            sibsSpgPaymentWidget.type = 'text/javascript';
            sibsSpgPaymentWidget.src = 'https://api.sibspayments.com/assets/js/widget.js?id=' + this.checkoutId;

            const formSibsPayment = this.renderer2.createElement('form');
            this.renderer2.setAttribute(formSibsPayment, 'class', 'paymentSPG');
            this.renderer2.setAttribute(formSibsPayment, 'spg-context', this.spgContext);
            this.renderer2.setAttribute(formSibsPayment, 'spg-config', this.spgConfig);
            this.renderer2.setAttribute(formSibsPayment, 'spg-style', this.spgStyle);

            setTimeout(() => {
              this.renderer2.appendChild(this.paymentForm.nativeElement, sibsSpgPaymentWidget);
              this.renderer2.appendChild(this.paymentForm.nativeElement, formSibsPayment);
            }, 900);
          }
        }
			}
		});
	}

	goBack() {
		this.location.back();
	}

  checkStatus(loading = false) {
    if (loading) {
      this.loading = true;
    }
    this.paymentService.status(this.orderId).subscribe((resp: any) => {
      if (resp.status == 'OK') {
        if (resp.data.status !== 'pending') {
          this.load();
          this.loading = false;
        } else {
          this.loading = false;
          if (this.order.status === 'Submetida' && this.order.payment_type !== 'MASTER VISA') {
            setTimeout(() => {
              this.checkStatus();
            }, 10000);
          }
        }
      } else {
        this.toastService.showError(resp.data.message);
        this.loading = false;
      }
    });
  }

  getBaseUrl() {
    return window.location.origin;
  }
}
