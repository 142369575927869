import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
	providedIn: 'root'
})
export class PaymentService {
	controller: string = 'payment/';

	constructor(private apiService: ApiService) {}

	mb_sibs(order_id: number) {
		return this.apiService.request(this.controller + 'multibanco', { order_id }, undefined, true, true);
	}

	mbway_sibs(order_id: number) {
		return this.apiService.request(this.controller + 'async', { order_id }, undefined, true, true);
	}

	cc_sibs(order_id: number) {
		return this.apiService.request(this.controller + 'create', { order_id }, undefined, true, true);
	}

	mb_eupago(order_id: number) {
		return this.apiService.request(this.controller + 'eupago-mb', { order_id }, undefined, true, true);
	}

	mbway_eupago(order_id: number) {
		return this.apiService.request(this.controller + 'eupago-mbw', { order_id }, undefined, true, true);
	}

	cc_eupago(order_id: number) {
		return this.apiService.request(this.controller + 'eupago-cc', { order_id }, undefined, true, true);
	}

  // Global
  status(order_id: number) {
    return this.apiService.request(this.controller + 'status', { order_id }, undefined, true, true);
  }

}
