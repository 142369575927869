<div class="row top-header d-none d-sm-flex">
  <div class="col-5 text-end">
    <p style="margin-top: 8px;">
      shop@opticaboavista.com
    </p>
  </div>
  <div class="col-2">
    <app-social-links></app-social-links>
  </div>
  <div class="col-5 text-start">
    <p>
      &nbsp;&nbsp;&nbsp;+351 220 969 940<br/>
      <small>(chamada para rede fixa)</small>
    </p>
  </div>
</div>

<div class="logo">
	<a href="/"><img src="assets/images/logo.jpg" /></a>
</div>

<nav class="navbar navbar-expand-lg flex-column" [ngClass]="{ 'disable-hover': disableHover }">
	<div class="container-fluid">
		<button class="navbar-toggler" type="button" data-bs-toggle="collapse" (click)="collapsed = !collapsed" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="w-100 justify-content-center" [ngClass]="{ collapse: collapsed, 'navbar-collapse': true }">
			<ul class="navbar-nav">
				<li class="nav-item menu-submenu" onclick="">
					<a type="button" class="nav-link">Produtos</a>
					<div class="container-fluid submenu">
						<div class="row justify-content-around">
							<div class="col" *ngFor="let col of categories">
								<ul class="nav-list" style="padding-left: 0">
									<li class="nav-item" *ngFor="let category of col">
										<a [href]="getPathAsString('/categoria', category)" [ngClass]="{ active: selectedCategory == category.id }" class="nav-link" role="button">
											<b>{{ category.name }}</b>
										</a>
										<a [href]="getPathAsString2('/categoria', category, 'subcategoria', subcategory)" [ngClass]="{ active: selectedSubCategory == subcategory.id }" *ngFor="let subcategory of category.subcategories" class="nav-link" role="button">{{ subcategory.name }}</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</li>
				<li class="nav-item menu-submenu" onclick="">
					<a class="nav-link" *ngIf="collapsed" role="button">Marcas</a>
					<a class="nav-link" *ngIf="!collapsed">Marcas</a>
					<div class="container-fluid submenu">
						<div class="row justify-content-around">
							<div class="col" *ngFor="let col of brands">
								<ul class="nav-list" style="padding-left: 0">
									<li class="nav-item" *ngFor="let brand of col">
										<a [href]="getPathAsString('/marca', brand)" class="nav-link" role="button" [ngClass]="{ active: selectedBrand == brand.id }">{{ brand.name }}</a>
									</li>
								</ul>
							</div>
						</div>
						<a class="nav-link text-center mt-2" href="/marcas">VER TODAS</a>
					</div>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="/promocoes">Promoções</a>
				</li>
				<li class="nav-item menu-submenu" onclick="">
					<a class="nav-link" role="button">Serviços</a>
					<div class="container-fluid submenu">
						<div class="row">
							<div class="col-12 col-md-4 col-lg-4 nav-item" *ngFor="let specialty of specialties">
								<a [href]="getPathAsString('/servico', specialty)" class="nav-link" role="button">{{ specialty.name }}</a>
							</div>
						</div>
					</div>
				</li>
				<li class="nav-item">
					<a class="nav-link" [ngClass]="activeRoute && activeRoute.includes('/quem-somos') ? 'active' : ''" href="/quem-somos">Sobre nós</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" [ngClass]="activeRoute && activeRoute.includes('/contactos') ? 'active' : ''" href="/contactos">Contactos</a>
				</li>
			</ul>
			<div class="menu-right" *ngIf="collapsed">
				<ul class="navbar-nav">
					<li class="nav-item">
						<a role="button" (click)="openModal('search')" class="nav-link">
							<svg version="1.2" xmlns="http://www.w3.org/2000/svg" overflow="visible" preserveAspectRatio="none" viewBox="0 0 24 24" width="28" height="28">
								<g><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" vector-effect="non-scaling-stroke" /></g>
							</svg>
						</a>
					</li>
					<li *ngIf="user" class="nav-item">
						<a href="/favoritos" class="nav-link" [ngClass]="activeRoute && activeRoute.includes('/favoritos') ? 'active' : ''">
							<svg version="1.2" xmlns="http://www.w3.org/2000/svg" overflow="visible" preserveAspectRatio="none" viewBox="0 0 24 24" width="28" height="28">
								<g>
									<path
										id="heart"
										d="M12,20c0.17,0,0.34-0.07,0.46-0.19l6.49-6.25c1.39-1.18,2.25-2.87,2.38-4.69C21.4,7.55,20.92,6.26,20,5.3  C19.01,4.4,17.69,3.93,16.35,4c-0.46,0-0.92,0.08-1.35,0.23c-0.44,0.14-0.86,0.35-1.25,0.6c-0.39,0.25-0.72,0.49-1,0.71  C12.47,5.77,12.21,6,11.96,6.25C11.71,6,11.45,5.77,11.17,5.54c-0.28-0.22-0.61-0.46-1-0.71C9.81,4.59,9.41,4.38,9,4.23  C8.57,4.08,8.11,4.01,7.65,4C6.31,3.93,4.99,4.4,4,5.3C3.08,6.26,2.6,7.56,2.67,8.88c0,0.49,0.08,0.98,0.24,1.44  c0.14,0.44,0.33,0.86,0.56,1.26c0.21,0.35,0.45,0.68,0.71,1c0.27,0.33,0.46,0.55,0.58,0.68c0.09,0.1,0.19,0.19,0.29,0.27l6.5,6.27  C11.67,19.93,11.83,20,12,20L12,20z"
										vector-effect="non-scaling-stroke"
									/>
								</g>
							</svg>
						</a>
					</li>
					<li class="nav-item">
						<a [href]="user ? '/perfil' : '/login'" class="nav-link" [ngClass]="activeRoute && (activeRoute.includes('/perfil') || activeRoute.includes('/login')) ? 'active' : ''">
							<svg version="1.2" xmlns="http://www.w3.org/2000/svg" overflow="visible" preserveAspectRatio="none" viewBox="0 0 24 24" width="30" height="30">
								<g>
									<path
										id="user"
										d="M18.58,15.57c-0.06-0.53-0.17-1.06-0.32-1.57c-0.14-0.48-0.34-0.94-0.61-1.36c-0.25-0.38-0.6-0.7-1-0.93  c-0.43-0.25-0.91-0.37-1.4-0.36c-0.86,0.86-2.03,1.34-3.24,1.32c-1.22,0.02-2.4-0.46-3.26-1.33c-0.49-0.01-0.97,0.11-1.4,0.36  c-0.4,0.23-0.75,0.55-1,0.93C6.09,13.06,5.89,13.52,5.76,14c-0.16,0.52-0.27,1.04-0.33,1.58c-0.06,0.55-0.09,1.11-0.09,1.67  c-0.01,0.7,0.23,1.38,0.67,1.93c0.36,0.5,0.93,0.8,1.55,0.82h8.89c0.62-0.02,1.19-0.32,1.56-0.82c0.44-0.55,0.67-1.24,0.65-1.95  C18.66,16.68,18.64,16.12,18.58,15.57z M14.84,5.18C14.1,4.41,13.08,3.98,12.01,4c-1.07-0.02-2.09,0.41-2.83,1.18  C8.42,5.92,7.99,6.94,8.01,8c-0.02,1.06,0.41,2.09,1.17,2.83c0.74,0.76,1.77,1.19,2.83,1.17c1.06,0.02,2.09-0.41,2.83-1.17  c0.76-0.74,1.19-1.77,1.17-2.83C16.02,6.94,15.6,5.92,14.84,5.18z"
										vector-effect="non-scaling-stroke"
									/>
								</g>
							</svg>
						</a>
					</li>
					<li class="nav-item">
						<a href="/carrinho" class="nav-link" [ngClass]="activeRoute && activeRoute.includes('/carrinho') ? 'active' : ''">
							<svg version="1.2" xmlns="http://www.w3.org/2000/svg" overflow="visible" preserveAspectRatio="none" viewBox="0 0 24 24" width="22" height="22">
								<g>
									<path
										d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"
										vector-effect="non-scaling-stroke"
									/>
								</g>
							</svg>
              {{ cartCount }}
						</a>
					</li>
				</ul>
			</div>

			<div class="menu-right row align-items-center m-2" *ngIf="!collapsed">
				<div class="col-3">
					<a role="button" (click)="openModal('search')" class="nav-link">
						<svg version="1.2" xmlns="http://www.w3.org/2000/svg" overflow="visible" preserveAspectRatio="none" viewBox="0 0 24 24" width="28" height="28">
							<g><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" vector-effect="non-scaling-stroke" /></g>
						</svg>
					</a>
				</div>
				<div *ngIf="user" class="col-3">
					<a href="/favoritos" class="nav-link" [ngClass]="activeRoute && activeRoute.includes('/favoritos') ? 'active' : ''">
						<svg version="1.2" xmlns="http://www.w3.org/2000/svg" overflow="visible" preserveAspectRatio="none" viewBox="0 0 24 24" width="28" height="28">
							<g>
								<path
									id="heart"
									d="M12,20c0.17,0,0.34-0.07,0.46-0.19l6.49-6.25c1.39-1.18,2.25-2.87,2.38-4.69C21.4,7.55,20.92,6.26,20,5.3  C19.01,4.4,17.69,3.93,16.35,4c-0.46,0-0.92,0.08-1.35,0.23c-0.44,0.14-0.86,0.35-1.25,0.6c-0.39,0.25-0.72,0.49-1,0.71  C12.47,5.77,12.21,6,11.96,6.25C11.71,6,11.45,5.77,11.17,5.54c-0.28-0.22-0.61-0.46-1-0.71C9.81,4.59,9.41,4.38,9,4.23  C8.57,4.08,8.11,4.01,7.65,4C6.31,3.93,4.99,4.4,4,5.3C3.08,6.26,2.6,7.56,2.67,8.88c0,0.49,0.08,0.98,0.24,1.44  c0.14,0.44,0.33,0.86,0.56,1.26c0.21,0.35,0.45,0.68,0.71,1c0.27,0.33,0.46,0.55,0.58,0.68c0.09,0.1,0.19,0.19,0.29,0.27l6.5,6.27  C11.67,19.93,11.83,20,12,20L12,20z"
									vector-effect="non-scaling-stroke"
								/>
							</g>
						</svg>
					</a>
				</div>
				<div class="col-3">
					<a [href]="user ? '/perfil' : '/login'" class="nav-link" [ngClass]="activeRoute && (activeRoute.includes('/perfil') || activeRoute.includes('/login')) ? 'active' : ''">
						<svg version="1.2" xmlns="http://www.w3.org/2000/svg" overflow="visible" preserveAspectRatio="none" viewBox="0 0 24 24" width="30" height="30">
							<g>
								<path
									id="user"
									d="M18.58,15.57c-0.06-0.53-0.17-1.06-0.32-1.57c-0.14-0.48-0.34-0.94-0.61-1.36c-0.25-0.38-0.6-0.7-1-0.93  c-0.43-0.25-0.91-0.37-1.4-0.36c-0.86,0.86-2.03,1.34-3.24,1.32c-1.22,0.02-2.4-0.46-3.26-1.33c-0.49-0.01-0.97,0.11-1.4,0.36  c-0.4,0.23-0.75,0.55-1,0.93C6.09,13.06,5.89,13.52,5.76,14c-0.16,0.52-0.27,1.04-0.33,1.58c-0.06,0.55-0.09,1.11-0.09,1.67  c-0.01,0.7,0.23,1.38,0.67,1.93c0.36,0.5,0.93,0.8,1.55,0.82h8.89c0.62-0.02,1.19-0.32,1.56-0.82c0.44-0.55,0.67-1.24,0.65-1.95  C18.66,16.68,18.64,16.12,18.58,15.57z M14.84,5.18C14.1,4.41,13.08,3.98,12.01,4c-1.07-0.02-2.09,0.41-2.83,1.18  C8.42,5.92,7.99,6.94,8.01,8c-0.02,1.06,0.41,2.09,1.17,2.83c0.74,0.76,1.77,1.19,2.83,1.17c1.06,0.02,2.09-0.41,2.83-1.17  c0.76-0.74,1.19-1.77,1.17-2.83C16.02,6.94,15.6,5.92,14.84,5.18z"
									vector-effect="non-scaling-stroke"
								/>
							</g>
						</svg>
					</a>
				</div>
				<div class="col-3">
					<a href="/carrinho" class="nav-link" [ngClass]="activeRoute && activeRoute.includes('/carrinho') ? 'active' : ''">
						<svg version="1.2" xmlns="http://www.w3.org/2000/svg" overflow="visible" preserveAspectRatio="none" viewBox="0 0 24 24" width="22" height="22">
							<g>
								<path
									d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"
									vector-effect="non-scaling-stroke"
								/>
							</g>
						</svg>
            {{ cartCount }}
					</a>
				</div>
			</div>
		</div>
	</div>
</nav>
