<div class="product-container">
  <div class="row main-loading" *ngIf="mainLoading">
    <p class="text-center">
      <svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M2,12A11.2,11.2,0,0,1,13,1.05C12.67,1,12.34,1,12,1a11,11,0,0,0,0,22c.34,0,.67,0,1-.05C6,23,2,17.74,2,12Z"><animateTransform attributeName="transform" type="rotate" dur="0.6s" values="0 12 12;360 12 12" repeatCount="indefinite" /></path>
      </svg>
    </p>
  </div>
	<div *ngIf="product && !mainLoading" class="product-content">
		<nav aria-label="breadcrumb mt-5">
			<ol class="breadcrumb">
				<li class="breadcrumb-item"><a href="/produtos">Produtos</a></li>
				<li class="breadcrumb-item">
					<a [href]="getPathAsString('/categoria', product.category)">{{ product.category.name }}</a>
				</li>
				<li class="breadcrumb-item active" aria-current="page">{{ product.name }}</li>
			</ol>
		</nav>
		<div class="row align-items-top">
			<div class="col-12 col-md-8">
				<div *ngIf="selectedImage" class="img-selected center" [style.background-image]="'url(' + selectedImage + ')'"></div>

				<div class="multi-images">
					<div *ngFor="let image of product.images">
						<div *ngIf="image" [ngClass]="{ 'image-active': image == selectedImage }" class="img mx-2" role="button" (click)="changeImage(image)" [style.background-image]="'url(' + image + ')'"></div>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-4">
				<h2>{{ product.brand.name }}</h2>
				<h1>{{ product.name }}</h1>
        <h5 *ngIf="!product.discount && product.sell_online">{{ price }}€</h5>
        <h5 *ngIf="product.discount && product.sell_online">
					<span class="discount-price">{{ this.product.price }}€</span> <b> {{ price }}€</b>
				</h5>
        <p *ngIf="product.discount && product.sell_online" class="m-0 mb-3">
					<span class="box-dark"> -{{ product.discount }}% </span>
					<span class="box-ligth">Promoção</span>
				</p>

        <select #related *ngIf="product.relatedProducts.length" aria-placeholder="Versões" (change)="changeVersion()" class="form-control"  name="versions">
          <option  value="">Outras Versões</option>
          <option *ngFor="let related of product.relatedProducts" [value]="related.id">{{ related.name }}</option>
        </select>

				<hr />

        <button *ngIf="product.sell_online && product.stock > 0" (click)="addProduct()"
                class="btn btn-block btn-primary my-1">Adicionar ao Carrinho
        </button>
        <div class="row" *ngIf="!product.sell_online || product.stock == 0">
          <div class="col-12">
            <p>Se está interessado neste produto, por favor contacte-nos.</p>
          </div>
          <div class="col-12 col-lg-6">
            <a href="mailto:{{ merchant.email }}?subject='Mais informações sobre o produto ' {{ product.name }}!" target="_blank" class="btn btn-block btn-primary my-1">
               <span class="align-bottom">
              <svg version="1.2" xmlns="http://www.w3.org/2000/svg" overflow="visible" preserveAspectRatio="none"
                   viewBox="0 0 24 24" width="24" height="24">
                <g>
                  <path
                    d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"
                    style="fill: rgb(255, 255, 255);" vector-effect="non-scaling-stroke"/>
                </g>
              </svg>
               </span>
              Email
            </a>
          </div>
          <div class="col-12 col-lg-6">
            <button (click)="openWhatsapp()" class="btn btn-block btn-primary my-1">
              <span class="align-bottom">
              <svg version="1.2" xmlns="http://www.w3.org/2000/svg" overflow="visible" preserveAspectRatio="none"
                   viewBox="-32 0 512 512" width="24" height="24"><path
                d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                style="fill: rgb(255, 255, 255);" vector-effect="non-scaling-stroke"/></svg>
              </span>
              WhatsApp
            </button>
          </div>
        </div>
				<button *ngIf="user" (click)="addFavorite()" class="btn btn-block btn-primary-outline my-1">Adicionar aos Favoritos</button>
				<button (click)="showShare = !showShare" class="btn btn-block btn-primary-outline my-1">Partilhar</button>
				<share-buttons *ngIf="showShare" theme="modern-dark" [include]="['copy', 'facebook', 'email', 'twitter', 'whatsapp']" [showIcon]="true" [showText]="false" [url]="url"> </share-buttons>
			</div>
		</div>
	</div>

  <div class="mt-5">
    <app-container-products *ngIf="product" [title]="'Produtos Relacionados'"
                            [products]="product.related"></app-container-products>
  </div>
</div>
