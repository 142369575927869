import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@app/models/user.model';
import { BasicComponent } from '@pages/basic/basic.component';
import { CartService, ProductService } from '@services/services.service';
import { environment } from '@env/environment';
import {Merchant} from "@models/merchant.model";

@Component({
	selector: 'app-product',
	templateUrl: './product.component.html',
	styleUrls: ['./product.component.scss']
})
export class ProductComponent extends BasicComponent implements OnInit {
  @ViewChild('related') related!: ElementRef;

	optionsForm: FormGroup;
	user: User;
	productId: number;
	product: any;
	selectedImage: string;
	slideConfig: any;
	price: number;
	options: any = null;
	showShare: boolean = false;
	showOptions: boolean = false;
	url: string = '';
  merchant: Merchant;
  mainLoading: boolean = true;

	constructor(private router: Router, private activatedRoute: ActivatedRoute, private productService: ProductService, private cartService: CartService, private formBuilder: FormBuilder) {
		super();

		this.user = this.storageService.get('user');
    this.merchant = this.storageService.get('merchant');

		this.optionsForm = this.formBuilder.group({
			quantity: [1, [Validators.required, Validators.min(1)]],
			pack_id: [null],
			eye: [null],
			curve: [null],
			diameter: [null],
			ball: [null],
			cylindrical: [null],
			axle: [null],
			addiction: [null],
			geometry: [null],
			curve2: [null],
			diameter2: [null],
			ball2: [null],
			cylindrical2: [null],
			axle2: [null],
			addiction2: [null],
			geometry2: [null]
		});

		this.activatedRoute.params.subscribe(async (params: any) => {
			this.productId = params.id;
			this.load();
		});
	}

	ngOnInit(): void {}

	load() {
		this.productService.one(this.productId).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.product = resp.data;
				this.price = this.product.price;

				this.url = `https://opticae.online/product/share?id=${this.product.id}&s=${window.btoa(encodeURIComponent(environment.url_base + 'product/' + this.product.id))}`;

				if (this.product.discount) {
					this.price = this.price - this.price * this.product.discount * 0.01;
				}

				this.selectedImage = this.product.images?.length > 0 ? this.product.images[0] : '';
				this.slideConfig = { slidesToShow: this.product.images?.length, slidesToScroll: 1 };
        this.mainLoading = false;
			}
		});
	}

	changeImage(image: string) {
		this.selectedImage = image;
	}

	addFavorite() {
		this.productService.favorite(this.product.id).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.product.favorite = !this.product.favorite;
				this.toastService.showSuccess('Adicionado/Removido com sucesso');
			}
		});
	}

	addProduct() {
		if (!this.showOptions && this.product.has_grade_lens) {
			this.showOptions = true;
		} else {
			this.showOptions = false;
			if (this.optionsForm.valid) {
				let discount = this.product.discount ? this.product.price * this.product.discount * 0.01 : 0;

				let product: any = {
					product_id: this.product.id,
					image: this.product.image,
					name: this.product.name,
					price: this.product.price - discount,
					original_price: this.product.price,
					discount: discount,
					grade_lens: this.options ?? null
				};

				Object.entries(this.optionsForm.value).forEach(([key, value]) => {
					product[key] = value;
				});

				this.cartService.addProduct(product);
			} else {
				this.toastService.showError('Preencha todos os campos obrigatórios');
			}
		}
	}

	addOptions(value: any) {
		this.options = value;
		this.addProduct();
	}

  openWhatsapp(number?: string) {
    let ua = navigator.userAgent;
    let whatsapp = `https://web.whatsapp.com/send?phone=${this.merchant.whatsapp}`;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      whatsapp = `https://api.whatsapp.com/send?phone=${this.merchant.whatsapp}`;
    }

    window.open(whatsapp, '_blank');
  }

  changeVersion() {
    let id = this.related.nativeElement.value;
    let product = this.product.relatedProducts.find((related: any) => related.id == id);
    this.router.navigate(['/produto', product.id, product.name]);

  }
}
