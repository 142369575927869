<h2 *ngIf="order" class="ob-title">Encomenda: {{ order.name }}</h2>

<div class="order-container">
	<p class="text-start">
		<a role="button" (click)="goBack()"><i class="fas fa-angle-left"></i> Voltar</a>
	</p>
  <div *ngIf="order" class="mt-5">
    <div class="row align-items-top">
      <div class="col-12 col-md-6">
        <!-- Delivery Method -->
        <h3>Método de Envio</h3>
        <p *ngIf="order.delivery_type == 1">Entrega no domicílio</p>
        <p *ngIf="order.delivery_type == 2">Levantamento na Loja</p>

        <!-- Delivery Address -->
        <div *ngIf="order.delivery_type == 1">
          <h3>Morada de Entrega</h3>
          <p>
            {{ order.address_delivery.address }} <br />
            {{ order.address_delivery.postcode }}, {{ order.address_delivery.city }} <br />
            {{ order.address_delivery.country }}
          </p>
        </div>

        <div *ngIf="order.delivery_type == 2">
          <h3>Loja</h3>
          <p>
            {{ order.store.name }} <br />
            {{ order.store.address }}
          </p>
        </div>

        <!-- Fiscal Address -->
        <div *ngIf="order.address_billing">
          <h3>Morada de Faturação</h3>
          <p *ngIf="order.address_billing">
            {{ order.address_billing.address }} <br />
            {{ order.address_billing.postcode }}, {{ order.address_billing.city }} <br />
            {{ order.address_billing.country }}
          </p>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <!-- Pay Method -->
        <h3>Pagamento</h3>
        <p *ngIf="order.payment_type !== 'PAY_AT_STORE' && order.payment_type !== 'BANK_TRANSFER' && order.payment_type !== 'MASTER VISA'">{{ order.payment_type }}</p>
        <p *ngIf="order.payment_type == 'PAY_AT_STORE'" class="text-uppercase">Pagar na Loja</p>
        <p *ngIf="order.payment_type == 'BANK_TRANSFER'" class="text-uppercase">Transferência Bancária</p>
        <p *ngIf="order.payment_type == 'MASTER VISA'" class="text-uppercase">Cartão de Crédito</p>

        <div *ngIf="order.payment_type !== 'PAY_AT_STORE'">
          <h3>Estado</h3>
          <p>
						<span [ngClass]="order.status">{{ order.status }}</span>&nbsp;
            <span *ngIf="order.status === 'Submetida'" (click)="checkStatus(true)">
							<i class="fas fa-sync-alt ml-2" [ngClass]="{ 'fa-spin': loading }"></i>
						</span>
          </p>
        </div>

        <div class="row">
          <div *ngIf="order.status === 'Submetida' && order.payment_type == 'MASTER VISA'">
            <p>Conclua o pagamento</p>
            <button class="btn btn-sm btn-primary btn-pay" (click)="openPay()">Pagar</button>
          </div>
        </div>

        <div *ngIf="order.status === 'Submetida' && order.payment_type == 'REFERÊNCIA MULTIBANCO'">
          <p>Conclua o pagamento usando estes dados</p>
          <p>
            <b>Entidade:</b> {{ order.mb_entity }} <br />
            <b>Referência:</b> {{ order.mb_reference }} <br />
            <b>Valor:</b> {{ order.mb_amount  }} <br />
            <b>Data Limite:</b> {{ order.mb_limit_date }}
          </p>
        </div>

        <div *ngIf="order.status === 'Submetida' && order.payment_type == 'BANK_TRANSFER'">
          <p>Conclua o pagamento usando estes dados</p>
          <p>
            <b>Banco:</b> {{ merchant.payments.bt_bank }} <br />
            <b>BIC/SWIFT:</b> {{ merchant.payments.bt_bicswift }} <br />
            <b>IBAN:</b> {{ merchant.payments.bt_iban }}
          </p>
        </div>

        <div *ngIf="order.payment_type == 'MBWAY'">
          <p *ngIf="order.status === 'Submetida'">
            Conclua o pagamento na App MBWay
          </p>
          <p>
            <b>Número usado:</b> {{ order.mbway }} <br />
          </p>
        </div>
      </div>
    </div>

    <!-- Notes -->
    <div class="col-12 mt-5">
      <h3>Notas/Observações</h3>
      <div [innerHTML]="order.notes"></div>
    </div>

    <h3 class="mt-5">O seu Pedido</h3>

    <hr />

    <div class="row align-items-center d-none d-lg-flex">
      <div class="col-12 col-md-2">
        <p>
          <b>Artigo</b>
        </p>
      </div>
      <div class="col-12 col-md-6">
        <p>
          <b>Descrição</b>
        </p>
      </div>
      <div class="col-12 col-md-2">
        <p class="text-end">
          <b>Quantidade.</b>
        </p>
      </div>
      <div class="col-12 col-md-2">
        <p class="text-end">
          <b>Preço</b>
        </p>
      </div>
    </div>

    <div class="row align-items-center my-2" *ngFor="let item of order.items">
      <div class="col-12 col-md-2">
        <img class="img center" [src]="item.image" alt="{{ item.name }}" />
      </div>
      <div class="col-12 col-md-6 mt-2 mt-md-0">
        <p>
          {{ item.name }} <span class="options-text" *ngIf="item.version"> <br />{{ item.version.name }}</span>
        </p>
      </div>
      <div class="col-6 col-md-2">
        <p class="text-start text-md-end">x {{ item.quantity }}</p>
      </div>
      <div class="col-6 col-md-2">
        <p class="text-end">{{ item.price }} €</p>
      </div>
    </div>

    <hr />

    <div class="row align-items-center">
      <div class="col-12 col-md-8"></div>
      <div class="col-12 col-md-4">
        <div class="row">
          <div class="col-6">Subtotal:</div>
          <div class="col-6 text-end">{{ order.price }} €</div>
          <div class="col-6">Saldo Descontado:</div>
          <div class="col-6 text-end">{{ order.discount }} €</div>
          <div class="col-6">Desconto Cupão:</div>
          <div class="col-6 text-end">{{ order.discount_coupon }} €</div>
          <div class="col-6">Taxa de envio:</div>
          <div class="col-6 text-end">{{ order.delivery }} €</div>
        </div>
      </div>
    </div>

    <div class="row align-items-center mt-5">
      <div class="col-12 col-md-8"></div>
      <div class="col-12 col-md-4">
        <div class="row">
          <div class="col-6">
            <h3>Total:</h3>
          </div>
          <div class="col-6 text-end">
            <h3>{{ order.total }} €</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
