<h2 class="ob-title">Resumo da Encomenda</h2>
<p *ngIf="!user" class="m-0 text-center">
	<small class="text-muted">(Para fazer checkout deve primeiro fazer login!)</small>
</p>

<div class="cart-container">
	<p *ngIf="!cart.products.length" class="text-center mt-4 mb-5">
    <svg _ngcontent-kce-c170="" version="1.2" xmlns="http://www.w3.org/2000/svg" overflow="visible" preserveAspectRatio="none" viewBox="0 0 24 24" width="100" height="100"><g _ngcontent-kce-c170=""><path _ngcontent-kce-c170="" d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" vector-effect="non-scaling-stroke"></path></g></svg>
    <br/>
		<small class="text-muted mt-4">O carrinho está vazio!</small>
	</p>

	<div class="row align-items-center product-line" *ngFor="let product of cart.products">
		<div class="col-2">
			<img class="img center" [src]="product.image" />
		</div>
		<div class="col-4">
			<p class="m-0">
				{{ product.name }}
			</p>
		</div>
		<div class="col-3">
			<p *ngIf="!product.discount" class="m-0">{{ product.price }}€</p>
			<p *ngIf="product.discount" class="m-0">
				<span class="discount-price">{{ product.original_price }}€</span> <strong> {{ product.price }}€</strong>
				<span *ngIf="product.discount" class="ms-3 m-0 text-center">
					<span class="box-dark"> -{{ product.discount }}% </span>
					<span class="box-ligth">Promoção</span>
				</span>
			</p>
		</div>
		<div class="col-2 form">
			<input type="number" (change)="changeQuant($event, product)" [value]="product.quantity" />
		</div>
		<div type="button" (click)="removeProduct(product)" class="col-1">
			<p class="m-0">
				<i class="fas fa-trash"></i>
			</p>
		</div>
	</div>

	<div *ngIf="cart.products.length && !user" class="row mt-5 mb-4 align-items-center">
    <div class="col-12 col-md-8"></div>
    <div class="col-12 col-md-4">
      <div class="card">
        <div class="card-header">
          <h3>Resumo</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6 col-md-6 text-start">
              <p><b>Subtotal:</b></p>
              <p><b>Saldo Descontado:</b></p>
              <p><b>Desconto Cupão:</b></p>
              <p><b>Taxa de envio:</b></p>
              <h4><b>Total:</b></h4>
            </div>
            <div class="col-6 col-md-6 text-end">
              <p>
                <b>{{ cart.total }} €</b>
              </p>
              <p>
                <b>{{ checkoutForm.value.discount }} €</b>
              </p>
              <p>
                <b>{{ checkoutForm.value.discount_coupon }} €</b>
              </p>
              <p>
                <b>{{ checkoutForm.value.delivery }} €</b>
              </p>
              <h4>
                <b>{{ total }} €</b>
              </h4>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-12 col-md-12"></div>
            <div class="col-12 col-md-12 text-end">
              <button class="btn btn-primary-outline my-1 me-1 " (click)="resetCart()">Limpar</button>
              <a role="button" href="/login" class="btn btn-primary my-1 fr">Finalizar</a>
            </div>
          </div>
        </div>
      </div>
    </div>
	</div>
</div>

<div *ngIf="cart.products.length && user" class="checkout-container">
	<h2 class="ob-title">Checkout</h2>
  <form class="form" (submit)="create()" [formGroup]="checkoutForm">
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="row align-items-top">
        <div class="col-12 col-md-12">
          <h3>Dados Pessoais</h3>

          <!-- Name -->
          <div class="mt-3">
            <p class="label">Nome</p>
            <input type="text" formControlName="name" />
          </div>

          <!-- Email -->
          <div class="mt-3">
            <p class="label">Email</p>
            <input type="text" formControlName="email" />
          </div>

          <!-- Nif -->
          <div class="mt-3">
            <p class="label">Nif</p>
            <input type="text" formControlName="nif" />
          </div>
        </div>

        <div class="col-12 col-md-12 mt-5 mt-lg-5">
          <!-- Delivery Type -->
          <h3>Entrega</h3>
          <div class="mt-4">
            <div class="form-check">
              <input class="form-check-input" type="radio" (change)="changeDeliveryType(1)" name="delivery_type" [checked]="checkoutForm.value.delivery_type == 1 ? true : false" />
              <label class="form-check-label">Envio para a morada</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" (change)="changeDeliveryType(0)" name="delivery_type" [checked]="checkoutForm.value.delivery_type == 0 ? true : false" />
              <label class="form-check-label">Levantamento em loja</label>
            </div>
          </div>

          <h3 class="mt-5">Morada</h3>

          <!-- Store -->
          <div *ngIf="checkoutForm.value.delivery_type == 0">
            <p class="label">Loja</p>
            <select formControlName="store_id">
              <option *ngFor="let store of stores" [value]="store.id">{{ store.name }}</option>
            </select>
          </div>

          <!-- Addresses -->
          <div *ngIf="checkoutForm.value.delivery_type == 1">
            <div *ngIf="!newAddressDelivery">
              <p class="label">Selecione uma Morada</p>
              <select (click)="changeDeliveryAddress($event)">
                <option *ngFor="let address of addresses_delivery" [value]="address.id">{{ address.address }}, {{ address.postcode }}, {{ address.city }} {{ address.country }}</option>
              </select>

              <button class="btn btn-primary my-2" (click)="changeNewAddress(true)">Nova Morada</button>
            </div>

            <div *ngIf="newAddressDelivery">
              <div class="mt-3">
                <p class="label">Morada</p>
                <input type="text" formControlName="address" />
              </div>

              <!-- PostCode -->
              <div class="mt-3">
                <p class="label">Código Postal</p>
                <input type="text" formControlName="postal" />
              </div>

              <!-- City -->
              <div class="mt-3">
                <p class="label">Cidade</p>
                <input type="text" formControlName="city" />
              </div>

              <!-- Country -->
              <div class="mt-3">
                <p class="label">País</p>
                <input type="text" formControlName="country" />
              </div>

              <button class="btn btn-primary my-2" (click)="changeNewAddress(false)">Cancelar</button>
            </div>
          </div>

          <!-- Address Fiscal -->
          <div class="form-check mt-3">
            <input class="form-check-input" (change)="selectFiscalAddress($event)" type="checkbox" />
            <label class="form-check-label">Adicionar morada de Faturação</label>
          </div>

          <div *ngIf="showFiscalAddress">
            <div *ngIf="!newAddressFiscal">
              <p class="label">Selecione uma Morada</p>
              <select (click)="changeFiscalAddress($event)">
                <option *ngFor="let address of addresses_fiscal" [value]="address.id">{{ address.address }}, {{ address.postcode }}, {{ address.city }} {{ address.country }}</option>
              </select>

              <button class="btn btn-primary my-2" (click)="changeNewFiscalAddress(true)">Nova Morada</button>
            </div>

            <div *ngIf="newAddressFiscal">
              <!-- Address -->
              <div class="mt-3">
                <p class="label">Morada</p>
                <input type="text" formControlName="address_fiscal" />
              </div>

              <!-- Postal Code -->
              <div class="mt-3">
                <p class="label">Código Postal</p>
                <input type="text" formControlName="postal_fiscal" />
              </div>

              <!-- City -->
              <div class="mt-3">
                <p class="label">Cidade</p>
                <input type="text" formControlName="city_fiscal" />
              </div>

              <!-- Country -->
              <div class="mt-3">
                <p class="label">País</p>
                <input type="text" formControlName="country_fiscal" />
              </div>

              <button class="btn btn-primary my-2" (click)="changeNewFiscalAddress(false)">Cancelar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-2"></div>
    <div class="col-12 col-md-4">
      <div class="col-12 col-md-12 mt-5 mt-lg-0">
        <h3>Pagamento</h3>
        <div class="mt-4">
          <div class="form-check" *ngIf="merchant.payments.mbway">
            <input class="form-check-input" type="radio" (change)="changePaymentType(1)" name="payment_type" [checked]="checkoutForm.value.payment_type == 1 ? true : false" />
            <label class="form-check-label">MBWAY</label>
          </div>
          <div class="form-check" *ngIf="merchant.payments.cc">
            <input class="form-check-input" type="radio" (change)="changePaymentType(2)" name="payment_type" [checked]="checkoutForm.value.payment_type == 2 ? true : false" />
            <label class="form-check-label">Cartão de Crédito</label>
          </div>
          <div class="form-check" *ngIf="merchant.payments.mb">
            <input class="form-check-input" type="radio" (change)="changePaymentType(3)" name="payment_type" [checked]="checkoutForm.value.payment_type == 3 ? true : false" />
            <label class="form-check-label">Referência Multibanco</label>
          </div>
        </div>

        <div *ngIf="checkoutForm.value.payment_type == 1" class="mt-3">
          <p class="label">Nº Telemóvel *</p>
          <input type="text" formControlName="mbway" />
        </div>

        <div class="mt-5" *ngIf="coupons.length > 0">
          <h3>Cupão</h3>

          <select (change)="changeCoupon($event)" formControlName="coupon_id">
            <option value="null">Selecione um cupão</option>
            <option *ngFor="let coupon of coupons" [value]="coupon.id">{{ coupon.amount }}{{ coupon.amount_type == "%" ? coupon.amount_type : "€" }}</option>
          </select>
        </div>

        <div class="mt-5" *ngIf="card && card.value > 0">
          <h3>Cartão</h3>
          <p><b>O seu Saldo:</b> {{ card.value }}€</p>
          <p class="label">Saldo a Usar</p>
          <input type="number" (change)="changeDiscount()" formControlName="discount" />
        </div>
      </div>
      <div *ngIf="cart.products.length && user" class="row mt-5">
        <div class="card">
          <div class="card-header">
            <h3>Resumo</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-6 col-md-6 text-start">
                <p><b>Subtotal:</b></p>
                <p><b>Saldo Descontado:</b></p>
                <p><b>Desconto Cupão:</b></p>
                <p><b>Taxa de envio:</b></p>
                <h4><b>Total:</b></h4>
              </div>
              <div class="col-6 col-md-6 text-end">
                <p>
                  <b>{{ cart.total }} €</b>
                </p>
                <p>
                  <b>{{ checkoutForm.value.discount }} €</b>
                </p>
                <p>
                  <b>{{ checkoutForm.value.discount_coupon }} €</b>
                </p>
                <p>
                  <b>{{ checkoutForm.value.delivery }} €</b>
                </p>
                <h4>
                  <b>{{ total }} €</b>
                </h4>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-12 col-md-12"></div>
              <div class="col-12 col-md-12 text-end">
                <button class="btn btn-primary-outline my-1 me-1 " (click)="resetCart()">Limpar</button>
                <button type="submit" [disabled]="checkoutForm.invalid" class="btn btn-primary my-1 fr">Finalizar</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
	</form>
</div>
