<h2 *ngIf="order" class="ob-title">Encomenda: {{ order.name }}</h2>

<div class="order-container">
	<p class="text-start">
		<a role="button" (click)="goBack()"><i class="fas fa-angle-left"></i> Voltar</a>
	</p>
  <div *ngIf="order" class="row mt-5">
    <div class="col-12 col-md-6">
    <div class="row align-items-top">
      <div class="col-12 col-md-12">
          <!-- Delivery Method -->
          <h3>Método de Envio</h3>
          <p *ngIf="order.delivery_type == 1">Entrega no domicílio</p>
          <p *ngIf="order.delivery_type == 2">Levantamento na Loja</p>

          <!-- Delivery Address -->
          <div *ngIf="order.delivery_type == 1">
            <h3>Morada de Entrega</h3>
            <p>
              {{ order.address_delivery.address }} <br />
              {{ order.address_delivery.postcode }}, {{ order.address_delivery.city }} <br />
              {{ order.address_delivery.country }}
            </p>
          </div>

          <div *ngIf="order.delivery_type == 2">
            <h3>Loja</h3>
            <p>
              {{ order.store.name }} <br />
              {{ order.store.address }}
            </p>
          </div>

          <!-- Fiscal Address -->
          <div *ngIf="order.address_billing">
            <h3>Morada de Faturação</h3>
            <p *ngIf="order.address_billing">
              {{ order.address_billing.address }} <br />
              {{ order.address_billing.postcode }}, {{ order.address_billing.city }} <br />
              {{ order.address_billing.country }}
            </p>
          </div>
        </div>
        <div class="col-12 col-md-6 mt-4">
          <!-- Pay Method -->
          <h3>Pagamento</h3>
          <p *ngIf="order.payment_type == 'MASTER VISA'" class="text-uppercase">Cartão de Crédito</p>
        </div>
        <div class="col-12 col-md-6 mt-4">
          <h3>Estado</h3>
          <p>
            <span [ngClass]="order.status">{{ order.status }}</span>&nbsp;
          </p>
        </div>
      </div>

      <!-- Notes -->
      <div class="col-12 mt-5">
        <h3>Notas/Observações</h3>
        <div [innerHTML]="order.notes"></div>
      </div>

      <h3 class="mt-5">O seu Pedido</h3>

      <hr />

      <div class="row align-items-center d-none d-lg-flex">
        <div class="col-12 col-md-2">
          <p>
            <b>Artigo</b>
          </p>
        </div>
        <div class="col-12 col-md-6">
          <p>
            <b>Descrição</b>
          </p>
        </div>
        <div class="col-12 col-md-2">
          <p class="text-end">
            <b>Quantidade.</b>
          </p>
        </div>
        <div class="col-12 col-md-2">
          <p class="text-end">
            <b>Preço</b>
          </p>
        </div>
      </div>

      <div class="row align-items-center my-2" *ngFor="let item of order.items">
        <div class="col-12 col-md-2">
          <img class="img center" [src]="item.image" alt="{{ item.name }}" />
        </div>
        <div class="col-12 col-md-6 mt-2 mt-md-0">
          <p>
            {{ item.name }} <span class="options-text" *ngIf="item.version"> <br />{{ item.version.name }}</span>
          </p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-start text-md-end">x {{ item.quantity }}</p>
        </div>
        <div class="col-6 col-md-2">
          <p class="text-end">{{ item.price }} €</p>
        </div>
      </div>

      <hr />

      <div class="row align-items-center">
        <div class="col-12 col-md-6"></div>
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-6">Subtotal:</div>
            <div class="col-6 text-end">{{ order.price }} €</div>
            <div class="col-6">Saldo Descontado:</div>
            <div class="col-6 text-end">{{ order.discount }} €</div>
            <div class="col-6">Desconto Cupão:</div>
            <div class="col-6 text-end">{{ order.discount_coupon }} €</div>
            <div class="col-6">Taxa de envio:</div>
            <div class="col-6 text-end">{{ order.delivery }} €</div>
          </div>
        </div>
      </div>

      <div class="row align-items-center mt-5">
        <div class="col-12 col-md-6"></div>
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-6">
              <h3>Total:</h3>
            </div>
            <div class="col-6 text-end">
              <h3>{{ order.total }} €</h3>
            </div>
          </div>
      </div>
    </div>
  </div>
    <div class="col-12 col-md-6">
      <div #paymentForm></div>
      <img class="sibs" *ngIf="order" src="assets/images/sibs.png" alt="sibs" />
    </div>
</div>
<!--  <div *ngIf="order?.status === 'Submetida' && order?.payment_type == 'MASTER VISA' && (isSpg)" [ngStyle]="{ display: display }" class="modal" tabindex="-1">-->
<!--    <div class="modal-dialog">-->
<!--      <div class="modal-content">-->
<!--        <div class="modal-body">-->
<!--          <div class="row">-->
<!--            <div class="col-12">-->
<!--              <button (click)="onCloseHandled()" type="button" class="btn-close"></button>-->
<!--            </div>-->
<!--            <div class="col-12">-->
<!--             -->
<!--            </div>-->
<!--            <div class="col-12">-->
<!--              <img class="sibs" src="assets/images/sibs.png" alt="sibs" />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  </div>
