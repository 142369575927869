import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BasicComponent } from '@pages/basic/basic.component';
import { Product } from '@models/models.model';
import { Router } from '@angular/router';

@Component({
	selector: 'app-container-products',
	templateUrl: './container-products.component.html',
	styleUrls: ['./container-products.component.scss']
})
export class ContainerProductsComponent extends BasicComponent implements OnInit {
	@Input() products: Product[] = [];
	@Input() category: string | null = null;
	@Input() link: string | null = null;
	@Input() categoryName: string | null = null;
	@Input() title: string = '';
	@Input() seeAllProduct: boolean = false;
	@Output() openProduct = new EventEmitter<boolean>();
	slideConfig: any = {
		slidesToShow: 4,
    arrows: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4
				}
			},
			{
				breakpoint: 775,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1
				}
			}
		]
	};

	constructor(private router: Router) {
		super();
	}

	ngOnInit(): void {}

	onOpenProduct() {
		this.openProduct.emit(true);
	}

	openProducts() {
		if (this.link) {
			window.location.href = this.link;
		} else if (this.category) {
			window.location.href = `/categoria/${this.category}/${this.categoryName}`;
		} else {
			window.location.href = '/produtos';
		}
	}
}
