import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '@pages/home/home.component';
import { FavoritesComponent } from '@pages/favorites/favorites.component';
import { AboutComponent } from '@pages/about/about.component';
import { ProductsComponent } from '@pages/products/products.component';
import { ProductComponent } from '@pages/product/product.component';
import { SpecialtyComponent } from '@pages/specialty/specialty.component';
import { InfoComponent } from '@pages/info/info.component';
import { ProfileComponent } from '@pages/profile/profile.component';
import { CartComponent } from '@pages/cart/cart.component';
import { OrdersComponent } from '@pages/orders/orders.component';
import { AddressesComponent } from '@pages/addresses/addresses.component';
import { PasswordComponent } from '@pages/password/password.component';
import { OrderComponent } from '@pages/order/order.component';
import { CouponsComponent } from '@pages/coupons/coupons.component';
import { ContactsComponent } from '@pages/contacts/contacts.component';
import { EventComponent } from '@pages/event/event.component';
import { LoginComponent } from '@pages/login/login.component';
import { EditUserComponent } from '@pages/edit-user/edit-user.component';
import { FamilyComponent } from '@pages/family/family.component';
import { LoyaltyComponent } from '@pages/loyalty/loyalty.component';
import { AboutItemComponent } from '@pages/about-item/about-item.component';
import { PromotionsComponent } from '@pages/promotions/promotions.component';
import { HighlightsComponent } from '@pages/highlights/highlights.component';
import {OrderPayComponent} from "@pages/order-pay/order-pay.component";

const routes: Routes = [
	{ path: '', redirectTo: '/inicio', pathMatch: 'full' },
	{ path: 'inicio', component: HomeComponent },
	{ path: 'quem-somos', component: AboutComponent },
	{ path: 'contactos', component: ContactsComponent },
	{ path: 'novidade/:id/:slug', component: EventComponent },
	{ path: 'colecao/:collection/:slug', component: ProductsComponent },
	{ path: 'categoria/:category/:slug', component: ProductsComponent },
	{ path: 'categoria/:category/subcategoria/:subcategory/:slug', component: ProductsComponent },
	{ path: 'marca/:brand/:slug', component: ProductsComponent },
	{ path: 'marcas', component: ProductsComponent },
	{ path: 'produtos', component: ProductsComponent },
	{ path: 'produto/:id/:slug', component: ProductComponent },
	{ path: 'servico/:id/:slug', component: SpecialtyComponent },
	{ path: 'favoritos', component: FavoritesComponent },
	{ path: 'info/:id', component: InfoComponent },
	{ path: 'perfil', component: ProfileComponent },
	{ path: 'moradas', component: AddressesComponent },
	{ path: 'encomendas', component: OrdersComponent },
	{ path: 'encomenda/:id', component: OrderComponent },
	{ path: 'pagar/:id', component: OrderPayComponent },
	{ path: 'password', component: PasswordComponent },
	{ path: 'carrinho', component: CartComponent },
	{ path: 'cupoes', component: CouponsComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'editar-perfil', component: EditUserComponent },
	{ path: 'familia', component: FamilyComponent },
	{ path: 'fidelizacao', component: LoyaltyComponent },
	{ path: 'quem-somos/:id', component: AboutItemComponent },
	{ path: 'promocoes', component: PromotionsComponent },
	{ path: 'destaques', component: HighlightsComponent }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
