import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';
import { environment } from '@env/environment';

@Injectable({
	providedIn: 'root'
})
export class ProductService {
	controller: string = 'product/';

	constructor(private apiService: ApiService) {}

	all(term?: string) {
		let data: any = {};
		let endpoint = 'search';

		if (term) {
			data.term = term;
			endpoint = 'search';
		}

		return this.apiService.request(this.controller + endpoint, data, 'id', false, true);
	}

	search(search?: string) {
		return this.apiService.request(this.controller + 'all', null, 'id');
	}

	one(id: number) {
		return this.apiService.request(this.controller + 'one', { id }, undefined, false, true);
	}

	highlights() {
		return this.apiService.request(this.controller + 'feature', { limit: 20 }, 'id', false, true);
	}

	accessories() {
		let categories = [{ category_id: 683 }];

		return this.apiService.request(this.controller + `filtercategory?id=${environment.merchantId}&limit=${20}&order=id&sort=desc`, { categories }, undefined, true, true);
	}

	favorite(id: number) {
		return this.apiService.request(this.controller + 'favorite', { id }, undefined, false, true);
	}

	filterHighlights(page: number = 0, limit: number = 16, category?: any, subcategories?: any, brands?: any, prices?: any, collection?: any) {
    let categories = category ? [{ category_id: category }] : [];

		let collections = collection ? [{ collection_id: collection }] : [];

		subcategories = subcategories.map((subcategory: any) => {
			return { subcategory_id: subcategory };
		});

		brands = brands.map((brand: any) => {
			return { brand_id: brand };
		});

		prices = prices.map((price: any) => {
			let aux = price.split(':');
			return { id: price, min: aux[0], max: aux[1] };
		});

		return this.apiService.request(this.controller + `highlights?id=${environment.merchantId}&page=${page}&limit=${limit}&order=id&sort=desc`, { categories, subcategories, brands, prices, collections }, undefined, true, true);
	}

	filterPromotions(page: number = 0, limit: number = 16, category?: any, subcategories?: any, brands?: any, prices?: any, collection?: any) {
    let categories = category ? [{ category_id: category }] : [];

		let collections = collection ? [{ collection_id: collection }] : [];

		subcategories = subcategories.map((subcategory: any) => {
			return { subcategory_id: subcategory };
		});

		brands = brands.map((brand: any) => {
			return { brand_id: brand };
		});

		prices = prices.map((price: any) => {
			let aux = price.split(':');
			return { id: price, min: aux[0], max: aux[1] };
		});

		return this.apiService.request(this.controller + `filterpromotions?id=${environment.merchantId}&page=${page}&limit=${limit}&order=id&sort=desc`, { categories, subcategories, brands, prices, collections }, undefined, true, true);
	}

	filterCategory(page: number = 0, limit: number = 16, category?: any, subcategories?: any, brands?: any, prices?: any, collection?: any) {
    let categories = category ? [{ category_id: category }] : [];

		let collections = collection ? [{ collection_id: collection }] : [];

		subcategories = subcategories.map((subcategory: any) => {
			return { subcategory_id: subcategory };
		});

		brands = brands.map((brand: any) => {
			return { brand_id: brand };
		});

		prices = prices.map((price: any) => {
			let aux = price.split(':');
			return { id: price, min: aux[0], max: aux[1] };
		});

		return this.apiService.request(this.controller + `filtercategory?id=${environment.merchantId}&page=${page}&limit=${limit}&order=id&sort=desc`, { categories, subcategories, brands, prices, collections }, undefined, true, true);
	}
}
